import {Controller} from '@hotwired/stimulus';
import $ from 'jquery';

/**
 * @property {Object} optionsValue
 */
export default class extends Controller {
    static values = {
        options: {type: Object, default: {}}
    };

    connect() {
        $(this.element).datepicker({
            language: 'hu-HU',
            autoclose: true,
            todayHighlight: true,
            todayBtn: true,
            ...this.optionsValue
        });
        this.element.autocomplete = 'off';
        this.element.nextElementSibling?.addEventListener('click', () => this.element.focus());
    }

    disconnect() {
        $(this.element).datepicker('destroy');
    }
}
