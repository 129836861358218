import {Controller} from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
    static values = {
        projectMultiple: {
            type: Boolean,
            default: false
        }
    };

    connect() {
        new TomSelect(this.element, {
            placeholder: 'Kérjük, válasszon!',
            render: {
                option: (data, escape) => this.#formatOption(data, escape),
                item: (data, escape) => this.#formatOption(data, escape),
                dropdown: () => `<div class="label-select-dropdown"></div>`
            }
        });
    }

    #formatOption(optionData, escape) {
        if (optionData.text === '') {
            return '';
        }

        const optColor = 'label-color-' + optionData.$option.dataset.color;
        const optProject = optionData.$option.dataset.project;
        const additionalText = this.projectMultipleValue ? ` (@${optProject})` : ''

        return `<div><span class="fa-solid fa-square label-color ${optColor}"></span>&nbsp;${escape(optionData.text)}${additionalText}</div>`;
    }
}
