import {Controller} from '@hotwired/stimulus';
import delegate from 'delegate-it';

/**
 * @property {String} urlValue
 * @property {String} deleteConfirmValue
 */
export default class extends Controller {
    static values = {
        url: {type: String},
        deleteConfirm: {type: String, default: 'Biztosan törlöd?'},
    };

    /** @var Tablebuilder */
    #tb;

    connect() {
        this.#tb = new Tablebuilder({provider: this.urlValue, wrapper: this.element});

        delegate('a.del', 'click', event => {
            event.preventDefault();
            if (confirm(this.deleteConfirmValue)) {
                fetch(event.delegateTarget.href)
                    .then(response => response.json())
                    .then(() => this.#tb.refresh());
            }
        }, {base: this.element});

        this.#tb.init();
        this.#tb.load();
    }
}
