export default function initStickyHeaderTablebuilder(container = document) {
    const CONTENT_STICKY_HEADER_TABLEBUILDER = container.querySelector('.card--sticky-tablebuilder');

    if (! CONTENT_STICKY_HEADER_TABLEBUILDER) return;

    const documentWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const tablebuilderHeader = container.querySelector('.tablebuilder .tablebuilder-form table thead');
    const cloneTablebuilderHeader = tablebuilderHeader.cloneNode(true);
    cloneTablebuilderHeader.classList.add('cloned-thead', 'fs--1');
    cloneTablebuilderHeader.style.left = `${tablebuilderHeader.getBoundingClientRect().left}px`;
    cloneTablebuilderHeader.style.right = `${documentWidth - tablebuilderHeader.getBoundingClientRect().right}px`;
    document.querySelector('.tablebuilder .tablebuilder-form').prepend(cloneTablebuilderHeader);

    window.addEventListener('scroll', event => {
        const stickyTablebuilderHeader = document.querySelector('.tablebuilder .tablebuilder-form .cloned-thead');

        stickyTablebuilderHeader.classList.toggle('show', tablebuilderHeader.getBoundingClientRect().top <= 90);
    });
}
