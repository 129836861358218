import {Controller} from '@hotwired/stimulus';

/**
 * @property {string} selectorValue
 * @property {string} urlValue
 */
export default class extends Controller {
    static values = {
        selector: {type: String},
        url: {type: String}
    };

    /** @var HtmlSelectElement */
    #mainSelect;

    #cache = new Map();

    async connect() {
        this.#mainSelect = document.querySelector(this.selectorValue);
        this.#mainSelect.addEventListener('input', this.#refresh.bind(this));
        await this.#refresh();
    }

    disconnect() {
        this.#mainSelect.removeEventListener('input', this.#refresh);
    }

    async #refresh() {
        const mainSelectedValues = this.#mainSelect.multiple
            ? Array.from(this.#mainSelect.selectedOptions).map(option => option.value)
            : this.#mainSelect.value;
        const data = await this.getData(mainSelectedValues);

        const selectedValues = this.element.multiple
            ? Array.from(this.element.selectedOptions).map(option => option.value)
            : [this.element.value];
        const isTomSelect = this.element.classList.contains('tomselected');

        this.element.disabled = true;
        if (isTomSelect) {
            this.element.tomselect.disable();
        }
        Array.from(this.element.options).forEach(i => i.remove());
        if (isTomSelect) {
            this.element.tomselect.clear();
            this.element.tomselect.clearOptions();
        }

        data.forEach(({id, name}) => this.element.options.add(new Option(name, id, false, selectedValues.includes(id.toString()))));
        if (isTomSelect) {
            this.element.tomselect.sync();
        }
        if (this.element.options.length > 0) {
            this.element.disabled = false;
            if (isTomSelect) {
                this.element.tomselect.enable();
            }
        }
    }

    async getData(value) {
        if (value === '' || Array.isArray(value) && value.length === 0) {
            return [];
        }

        const query = Array.isArray(value) ? value.map(v => `values[]=${v}`).join('&') : `value=${value}`;

        const getFromCache = async query => {
            return this.#cache.get(query);
        };

        const fetchAndCache = async query => {
            const url = `${this.urlValue}?${query}`;
            const data = await (await fetch(url).catch(error => error))
                .json()
                .catch(error => error);

            this.#cache.set(query, data);
            return data;
        };

        return this.#cache.has(query)
            ? await getFromCache(query)
            : await fetchAndCache(query);
    }
}
