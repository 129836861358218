import {Controller} from '@hotwired/stimulus';

/**
 * @property {string} listEndpointValue
 * @property {string} changePasswordEndpointValue
 * @property {HTMLFormElement} formTarget
 */
export default class extends Controller {
    static targets = ['form'];
    static values = {
        listEndpoint: String,
        changePasswordEndpoint: String
    }

    connect() {
        this.submit();
    }

    filter() {
        this.submit(new FormData(this.formTarget));
    }

    submit(data = new FormData()) {
        fetch(this.listEndpointValue, {method: 'POST', body: data})
            .then(response => response.text())
            .then(response => {
                this.element.innerHTML = response;

                document.dispatchEvent(new CustomEvent('wd:domChanged')); // Legacy event to init modals
            });
    }

    resetForm() {
        Array.from(this.formTarget.elements).forEach(el => {
            switch (el.type.toLowerCase()) {
                case 'text':
                    el.value = '';
                    break;
                case 'select-one':
                    el.selectedIndex = -1;
                    break;
            }
        });

        this.filter();
    }

    changePassword({params: {mail}}) {
        if (!mail || !this.changePasswordEndpointValue) {
            alert('A "mail" paraméter és "changePasswordEndpoint" value megadása kötelező.');
            return;
        }

        if (!confirm(`Biztosan új jelszót szeretnél generálni a következő felhasználónak: ${mail}?`)) {
            return;
        }

        const data = new FormData();
        data.append('mail', mail);
        fetch(this.changePasswordEndpointValue, {method: 'POST', body: data})
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    alert(`Új jelszó: ${response.message.password}`);
                } else {
                    alert(response.message);
                }
            });
    }
}
