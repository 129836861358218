import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    handleKeydown(e) {
        // engedélyezett billentyűk név szerint
        const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'Delete'];
        // engedélyezett navigációs billentyűk
        const navigationKeys = ['Home', 'End', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
        if (allowedKeys.includes(e.key) || navigationKeys.includes(e.key)) {
            return;
        }

        // engedélyezett kombinációk (Ctrl+A, Ctrl+C)
        if (e.ctrlKey && (e.key === 'a' || e.key === 'c')) {
            return;
        }

        // csak számokat engedünk (felső számsor és numerikus pad)
        if (
            !e.shiftKey && e.key >= '0' && e.key <= '9'
            || e.code >= 'Numpad0' && e.code <= 'Numpad9'
        ) {
            return;
        }

        // minden más billentyű letiltása
        e.preventDefault();
    }
}
