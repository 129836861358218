export default function initSensitiveData(container = document) {
    container.querySelectorAll('.sensitive-data-container').forEach(sensitiveData => {
        if (! sensitiveData.matches('[type="checkbox"]')) {
            sensitiveData.addEventListener('click', event => {
                event.stopPropagation();

                if (! confirm('Biztos meg szeretnéd jeleníteni az érzékeny adatokat?')) return;

                event.currentTarget.querySelector('.sensitive-data').classList.remove('sensitive-data--hide');
            });
        }
    });
}
