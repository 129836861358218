import delegate from 'delegate-it';

export default function initFavoriteLinks(container = document) {
    const favoriteLinksList = container.querySelector('.favorite-links-container');

    const selectText = (element) => {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
    }

    const addFavoriteLink = (event) => {
        event.preventDefault();

        const currentUrl = new URL(document.URL);
        const data = new FormData();
        data.append('current_url', currentUrl.pathname + currentUrl.search);
        data.append('title', currentUrl.searchParams.get('ajax_title') || document.title);

        fetch('/admin/favouritelinks/add_link/', {
            method: 'POST',
            body: data,
        })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    document.querySelector('.favorite-links-container')
                        .insertAdjacentHTML(
                            'beforeend',
                            `<div class="favourite-link-container d-flex justify-content-between">
                                <a class="nav-link favourite-link" href="${response.data.link}"
                                   role="button" data-favourite-id="${response.data.id}" title="${response.data.title}">
                                    <div class="d-flex align-items-center">
                                        <span class="nav-link-icon"><i class="fa-solid fa-star"></i></span>
                                        <span class="nav-link-text ps-1">${response.data.title}</span>
                                    </div>
                                </a>
                                <div class="d-flex align-items-center gap-2 favourite-links-toolbar">
                                    <a class="favourite-edit edit-favorite-btn text-success" href="#"><i class="fa-solid fa-pencil"></i></a>
                                    <a class="favourite-del delete-favorite-btn text-danger" href="#"><i class="fa-solid fa-trash"></i></a>
                                </div>
                            </div>`
                        );
                }
                bootstrap.showToast({
                    body: response.message,
                    closeButton: true,
                    toastClass: response.success ? 'success' : 'error',
                });
            });
    }

    const editFavoriteLink = (event) => {
        event.preventDefault();

        const favoriteLink = event.target.parentNode.parentNode.parentNode.querySelector('.favourite-link .nav-link-text');
        favoriteLink.contentEditable = true;
        favoriteLink.focus();
        selectText(favoriteLink);
        favoriteLink.addEventListener('keydown', ev => {
            if (ev.key === 'Escape') {
                favoriteLink.contentEditable = false;

                return;
            }

            if (ev.key === 'Enter') {
                ev.preventDefault();
                favoriteLink.contentEditable = false;

                const data = new FormData();
                data.append('title', favoriteLink.innerText);

                fetch(`/admin/favouritelinks/change_name/${favoriteLink.dataset.favouriteId}`, {
                    method: 'POST',
                    body: data,
                })
                    .then(response => response.json())
                    .then(response => {
                        bootstrap.showToast({
                            body: response.message,
                            closeButton: true,
                            toastClass: response.success ? 'success' : 'error',
                        });
                    });
            }
        });
    }

    const deleteFavoriteLink = (event) => {
        event.preventDefault();

        if (!confirm('Biztos törölni szeretnéd a linket?')) return;

        const favoriteLink = event.target.parentNode.parentNode.parentNode.querySelector('.favourite-link');

        fetch(`/admin/favouritelinks/delete/${favoriteLink.dataset.favouriteId}`, {method: 'POST'})
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    favoriteLink.parentElement.remove();
                }
                bootstrap.showToast({
                    body: response.message,
                    closeButton: true,
                    toastClass: response.success ? 'success' : 'error',
                });
            });
    }

    container.getElementById('addPageToFavorites')?.addEventListener('click', () => {
        addFavoriteLink(event);
    });

    delegate('.edit-favorite-btn', 'click', editFavoriteLink, {base: favoriteLinksList});
    delegate('.delete-favorite-btn', 'click', deleteFavoriteLink, {base: favoriteLinksList});
}
