import {Controller} from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
    connect() {
        new TomSelect(this.element, {
            placeholder: 'Kérjük, válasszon!',
            render: {
                option: (data, escape) => this.#formatDropdownOption(data, escape),
                item: (data, escape) => this.#formatSelectedOption(data, escape),
                dropdown: () => `<div class="user-select-dropdown"></div>`
            }
        });
    }

    #formatDropdownOption(optionData, escape) {
        if (optionData.text === '') {
            return '';
        }

        const optImage = this.#getProfileImage(optionData);

        // full name and nick
        const text = escape(optionData.text);

        return `<div><img src="${optImage}" class="user-pic">&nbsp;${text}</div>`;
    }

    #formatSelectedOption(optionData, escape) {
        if (optionData.text === '') {
            return '';
        }

        const optImage = this.#getProfileImage(optionData);

        let text = escape(optionData.text);
        if (this.element.multiple) {
            // only nick
            text = text.substring(text.search(' - ') + 3);
        } else {
            // only full name
            text = text.substring(0, text.search(' - '));
        }

        return `<div><img src="${optImage}" class="user-pic">&nbsp;${text}</div>`;
    }

    #getProfileImage(optionData) {
        let optImage = optionData.$option.dataset.image;

        return optImage
            ? '/userfiles/users/resized/' + optImage
            : '/media/admin/images/user_profile.png';
    }
}
