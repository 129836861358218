import 'bootstrap5';
import startStimulus from '../bootstrap.js';
import handleNavbarVerticalCollapsed from './new-layout/navbar-vertical';
import navbarTopDropShadow from './new-layout/navbar-top';
import searchInit from './new-layout/search';
import initFavoriteLinks from './new-layout/favorite-links';
import initSensitiveData from './new-layout/sensitive-data';
import initToastsMessages from './new-layout/toasts';
import initTableBuilder from './new-layout/tablebuilder/index';
import SimpleBar from 'simplebar';

import '../scss/symfony.scss';

const initOnLoad = (container = document) => {
    startStimulus();
    handleNavbarVerticalCollapsed();
    navbarTopDropShadow();
    searchInit();
    initFavoriteLinks();
    initSensitiveData();
    initToastsMessages();
    initTableBuilder();

    container.querySelectorAll('.scrollbar-overlay').forEach(el => {
        new SimpleBar(el);
    });
};

document.addEventListener('DOMContentLoaded', () => initOnLoad());
