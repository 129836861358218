export default function initFixHeightTablebuilder(container = document) {
    const CONTENT_FIX_TABLEBUILDER = container.querySelector('.content--fix-tablebuilder');

    if (! CONTENT_FIX_TABLEBUILDER) return;

    function setTablebuilderHeight() {
        let contentFixTablebuilderHeight = 0;
        for (const child of CONTENT_FIX_TABLEBUILDER.children) {
            if (! child.classList.contains('card')) {
                contentFixTablebuilderHeight += child.offsetHeight + parseFloat(window.getComputedStyle(child)['marginTop']) + parseFloat(window.getComputedStyle(child)['marginBottom']);
            }
        }

        const tableBuilderContent = CONTENT_FIX_TABLEBUILDER.querySelector('.card--fix-tablebuilder');
        tableBuilderContent.style.height = `calc(calc(100vh - 93px) - ${contentFixTablebuilderHeight}px)`;

        const cardHeaderHeight = tableBuilderContent.querySelector('.card-header').offsetHeight;
        const tablePagerHeight = tableBuilderContent.querySelector('.table-pager').offsetHeight;
        const tableHeaderHeight = tableBuilderContent.querySelector('.content--fix-tablebuilder .tablebuilder table thead').offsetHeight;

        const tbodyContainer = container.querySelector('.content--fix-tablebuilder .tablebuilder table tbody');
        const availableHeight = tableBuilderContent.offsetHeight - cardHeaderHeight - tablePagerHeight - tableHeaderHeight;

        tbodyContainer.style.height = `calc(${availableHeight}px - 3.5rem)`;
    }

    setTablebuilderHeight();

    window.addEventListener('resize', setTablebuilderHeight);

}
