require('../bootstrap');

import 'bootstrap5';
import '../scss/symfony.scss';

import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

import handleNavbarVerticalCollapsed from './new-layout/navbar-vertical';
import navbarTopDropShadow from './new-layout/navbar-top';
import searchInit from './new-layout/search';
import initFavoriteLinks from './new-layout/favorite-links';
import initSensitiveData from './new-layout/sensitive-data';
import initFixHeightTablebuilder from './new-layout/tablebuilder-fix-height';
import initStickyHeaderTablebuilder from './new-layout/tablebuilder-sticky-header';
import initToastsMessages from './new-layout/toasts';

// import './components/favorite-links';
import TableBuilder from '../../vendor/optimus/table-builder-bundle/assets/js/table-builder';

const initOnLoad = (container = document) => {
    // window.confirm = function(msg) {
    //     console.log(msg); // do something with the message
    // };

    handleNavbarVerticalCollapsed();
    navbarTopDropShadow();
    searchInit();
    initFavoriteLinks();
    initSensitiveData();
    initToastsMessages();

    container.querySelectorAll('.tablebuilder').forEach(elem => {
        new TableBuilder(elem, {url: elem.dataset.url});
        elem.addEventListener('tb.initialized', function () {
            initFixHeightTablebuilder();
            initStickyHeaderTablebuilder();
        });
    });

    container.querySelectorAll('.scrollbar-overlay').forEach(el => {
        new SimpleBar(el);
    });
};

document.addEventListener('DOMContentLoaded', () => initOnLoad());
