export default class TableBuilder {
    /** @type {HTMLElement} */
    #element = null;
    /** @type {HTMLElement} */
    #cardElement = null;
    #currentPage = 0;
    #lastPage = 0;

    #options = {
        autoLoad: true,
        url: '',
        heightType: 'sticky' // fix/max/sticky
    };

    #fetchOptions = {
        cache: 'no-cache',
        credentials: 'same-origin'
    };

    constructor(element, options = {}) {
        this.#element = element;
        this.#cardElement = this.#element.closest('.card');
        Object.assign(this.#options, options);

        // Locale selector
        this.delegate('click', '.tb-change-locale', event => this.load({locale: event.delegatedTarget.dataset.locale}));
        // First page
        this.delegate('click', '.tb-page-first.tb-link', () => this.load({page: 1}));
        // Previous page
        this.delegate('click', '.tb-page-prev.tb-link', () => this.load({page: this.#currentPage - 1}));
        // Next page
        this.delegate('click', '.tb-page-next.tb-link', () => this.load({page: this.#currentPage + 1}));
        // Last page
        this.delegate('click', '.tb-page-last.tb-link', () => this.load({page: this.#lastPage}));
        // Pager
        this.delegate('click', '.tb-page-num.tb-link', event => this.load({page: event.delegatedTarget.textContent}));
        // Result per page
        this.delegate('change', '.tb-rpp', event => this.load({page: 1, results_per_page: event.delegatedTarget.value}));
        // Reset
        this.delegate('click', '.tb-reset', () => this.load({reset: true}));
        // Filter
        this.delegate('submit', 'form', () => this.filter());
        // Select filter
        this.delegate('change', 'select', event => {
            if (event.delegatedTarget.dataset.submitOnChange) {
                this.filter();
            }
        });
        // Sort
        this.delegate('click', '.tb-sort', event => {
            const direction = event.delegatedTarget.classList.contains('tb-sort-asc') ? 'DESC' : 'ASC';
            this.load({
                order_column: event.delegatedTarget.dataset.name,
                order_direction: direction
            });
        });
        // Ajax link
        this.delegate('click', '.tb-ajax-action', event => {
            if (event.delegatedTarget.dataset.confirm && !confirm(event.delegatedTarget.dataset.confirm)) {
                return false;
            }

            fetch(event.delegatedTarget.dataset.url || event.delegatedTarget.href, this.#fetchOptions)
                .then(response => response.json())
                .then(response => {
                    this.load();
                    this.#element.dispatchEvent(
                        new CustomEvent('tb.ajax-action.response', {bubbles: true, detail: {response}})
                    );
                });
        });

        if (this.#options.autoLoad) {
            this.load(this.getUrlVars());
        }

        this.#element._tableBuilder = this;
    }

    delegate(eventType, selector, callback) {
        this.#element.addEventListener(eventType, event => {
            let delegatedTarget;

            // Selector matches event target
            if (event.target.matches(selector)) {
                delegatedTarget = event.target;
            } else {
                // One of the elements matched by the selector contains the event target
                for (const el of this.#element.querySelectorAll(selector)) {
                    if (el.contains(event.target)) {
                        delegatedTarget = el;
                        break;
                    }
                }
            }

            if (delegatedTarget) {
                event.preventDefault();
                event.delegatedTarget = delegatedTarget;
                callback(event);
            }
        });
    }

    getUrlVars() {
        const map = {};
        location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => map[key] = value);

        return map;
    }

    init() {
        this.#currentPage = parseInt(this.#element.querySelector('.tb-page-current').textContent);
        this.#lastPage = parseInt(this.#element.querySelector('.tb-page-last').dataset.lastPage);
        this.#element
            .querySelectorAll('.tb-sort, .tb-page-num:not(.tb-page-current)')
            .forEach(el => el.classList.add('tb-link'));

        if (this.#currentPage > 1) {
            this.#element
                .querySelectorAll('.tb-page-first, .tb-page-prev')
                .forEach(el => el.classList.add('tb-link'));
        }
        if (this.#currentPage < this.#lastPage) {
            this.#element
                .querySelectorAll('.tb-page-next, .tb-page-last')
                .forEach(el => el.classList.add('tb-link'));
        }

        this.updateTableBuilderHeight();

        this.#element.dispatchEvent(new Event('tb.initialized', {bubbles: true}));
    }

    filter() {
        const filters = {};
        Array.from(this.#element.querySelectorAll('.tb-filter:not(:disabled)'))
            .filter(el => (el.type !== 'checkbox' && el.type !== 'radio') || el.checked)
            .forEach(el => {
                filters[el.name] = el.tagName === 'SELECT'
                    ? Array.from(el.selectedOptions).map(op => op.value)
                    : el.value;
            });
        this.load(filters);
    }

    load(params = {}) {
        fetch(`${this.#options.url}?${(new URLSearchParams(params)).toString()}`, this.#fetchOptions)
            .then(response => response.text())
            .then(html => {
                this.#element.innerHTML = html;
                this.init();
                this.#element.dispatchEvent(new Event('tb.loaded', {bubbles: true}));
                this.#element.dispatchEvent(new CustomEvent('DOMContentUpdated', {bubbles: true, detail: {target: this.#element}}));
            });
    }

    getSelectedIds(selector = '.tb-checkbox') {
        return Array.from(this.#element.querySelectorAll(`${selector}:checked`)).map(elem => elem.value);
    }

    updateTableBuilderHeight() {
        if (this.#options.heightType === 'fix') {
            this.#setTableBuilderFixHeight();
            window.addEventListener('resize', () => this.#setTableBuilderFixHeight())
        } else if (this.#options.heightType === 'sticky') {
            this.#configureTableBuilderStickyHeader();
        }
    }

    #setTableBuilderFixHeight() {
        const mainContainer = this.#cardElement.closest('.content--fix-tablebuilder');

        let contentHeight = 0;
        for (const child of mainContainer.children) {
            if (child !== this.#cardElement) {
                contentHeight += child.offsetHeight
                    + parseFloat(window.getComputedStyle(child)['marginTop'])
                    + parseFloat(window.getComputedStyle(child)['marginBottom']);
            }
        }

        this.#cardElement.style.height = `calc(calc(100vh - 93px) - ${contentHeight}px)`;

        const cardHeader = this.#cardElement.querySelector('.card-header');
        const cardHeaderHeight = cardHeader ? cardHeader.offsetHeight : 0;
        const tablePagerHeight = this.#cardElement.querySelector('.table-pager').offsetHeight;
        const tableHeaderHeight = this.#cardElement.querySelector('.tablebuilder table thead').offsetHeight;

        const tbodyContainer = this.#cardElement.querySelector('.tablebuilder table tbody');
        const availableHeight = this.#cardElement.offsetHeight - cardHeaderHeight - tablePagerHeight - tableHeaderHeight;

        tbodyContainer.style.height = `calc(${availableHeight}px - 3.5rem)`;
    }

    #configureTableBuilderStickyHeader() {
        const documentWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const tableBuilderHeader = this.#cardElement.querySelector('.tablebuilder-form table thead');
        const cloneTableBuilderHeader = tableBuilderHeader.cloneNode(true);

        cloneTableBuilderHeader.classList.add('cloned-thead', 'fs--1');
        cloneTableBuilderHeader.style.left = `${tableBuilderHeader.getBoundingClientRect().left}px`;
        cloneTableBuilderHeader.style.right = `${documentWidth - tableBuilderHeader.getBoundingClientRect().right}px`;

        this.#cardElement.querySelector('.tablebuilder-form').prepend(cloneTableBuilderHeader)

        window.addEventListener('scroll', () => {
            const stickyTableBuilderHeader = this.#cardElement.querySelector('.tablebuilder-form .cloned-thead');
            stickyTableBuilderHeader.classList.toggle('show', tableBuilderHeader.getBoundingClientRect().top <= 90);
        });
    }
}
