import TableBuilder from './table-builder.js';

export default function initTableBuilder(container = document) {
    container.querySelectorAll('.tablebuilder')
        .forEach(elem => {
            const tb = new TableBuilder(elem, {
                autoLoad: false,
                url: elem.dataset.url,
                heightType: elem.dataset.heightType
            });
            tb.init();
        });
}
